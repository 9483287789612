<template>
  <div class="w-full flex flex-col h-full justify-between">
    <div class="w-full hidden md:flex md:flex-row md:flex-wrap justify-around stack-screen">
      <div class="md:w-1/2 lg:w-1/4 p-1" v-for="item in testTechnologies" :key=item>
        <StackItem :technologyID="item"/>
      </div>
    </div>
  </div>
</template>

<script>

import StackItem from './StackItem.vue';
export default {
  name: 'StacksScreen',
  components: {
    StackItem,
  },
  computed: {
    testTechnologies() {
      return this.$store.getters.technologies;
    },
  },
  methods: {
  },
};
</script>
