<template>
  <div class="tv-container h-full flex flex-col text-white bg-gray-200">
    <div class="hidden lg:flex w-full h-8">
      <div class="w-4/5 text-center text-black leading-8 text-xl michroma-regular-font">
        Wellcome to the Milos' site
      </div>
      <div class="w-1/5"></div>
    </div>
    <div class="h-2 lg:hidden">
      <!-- header placeholder form < lg -->
    </div>
    <div class="flex-auto screen-main-container">
      <div class="flex flex-col md:flex-row h-full pl-1 md:pl-2 pr-1 md:pr-0 justify-between">
        <div class="tv-screen w-full md:w-3/4 2xl:w-4/5 p-4 flex-grow md:flex-grow-0">
          <div class="flex flex-col outer-screen">
            <div class="inner-screen robotoMono-font">
              <router-view></router-view>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/4 2xl:w-1/5 flex flex-col-reverse px-0 md:flex-row md:px-2">
            <SwitchControlPanel/>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex w-full h-8">
      <div class="w-4/5 text-center leading-8 text-black text-lg michroma-regular-font">Powered by VueJS 3</div>
      <div class="w-1/5"></div>
    </div>
    <div class="h-2 lg:hidden text-xs">
      <!-- footer placeholder form < lg -->
    </div>
  </div>
</template>

<script>
import SwitchControlPanel from './SwitchControlPanel.vue';
export default {
  name: 'TvBoard',
  components: {
    SwitchControlPanel,
  },
  props: {
    msg: String,
  },
};
</script>
