import {createRouter, createWebHistory} from 'vue-router';
import {createApp} from 'vue';
import {createStore} from 'vuex';
import App from './App.vue';
import './main.css';
import './index.css';
import './tvboard.css';
import './glitch.scss';
import AboutMe from './components/AboutMe.vue';
import Experience from './components/Experience.vue';
import Projects from './components/Projects.vue';
import Blogs from './components/Blogs.vue';
import SchoolProjects from './components/SchoolProjects.vue';
import Credits from './components/Credits.vue';
import ProejctsOverview from './components/ProejctsOverview.vue';
import ProjectsMain from './components/ProjectsMain.vue';
import SingleProject from './components/SingleProject.vue';

import {ProjectTechnologies} from './constants.js';

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {path: '/', redirect: '/about'},
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      redirect: '/ch6',
    },
    {path: '/about', component: AboutMe},
    {path: '/experience', component: Experience},
    {path: '/school_projects', component: SchoolProjects},
    {path: '/credits', component: Credits},
    {
      name: 'projects',
      path: '/projects',
      component: Projects,
      children: [
        {
          name: 'projectsMain',
          path: '/projects/main',
          component: ProjectsMain,
        },
        {
          name: 'language',
          path: '/projects/:lang',
          component: ProejctsOverview,
        },
        {
          name: 'singleProject',
          path: '/projects/:lang/:id',
          component: SingleProject,
        },
      ],
    },
    {path: '/blogs', component: Blogs},
  ],
});

const store = createStore({
  state() {
    return {
      numberOfProjects: 0,
      selectedProjectId: 0,
      selectedProject: {},
      selectedProjectsByLanguage: {},
      selectedLanguage: '',
      technologyIds: [],
      showProjectsButtons: false,
      showChannelsButtons: false,
      switchPosition: 0,
      schoolProjects: {},
    };
  },
  getters: {
    technologies: (state) => {
      return state.technologyIds;
    },
    numOfProject: (state) => {
      return state.numberOfProjects;
    },
    projectsLanguage: (state) => {
      return state.selectedProjectsByLanguage;
    },
    currentProject: (state) => {
      return state.selectedProject;
    },
    currentProjectID: (state) => {
      return state.selectedProjectId;
    },
    currentProjectLanguage: (state) => {
      return state.selectedLanguage;
    },
    switchPosition: (state) => {
      return state.switchPosition;
    },
    showProjectButtons: (state) => {
      return state.showProjectsButtons;
    },
    schoolProjects: (state) => {
      return state.schoolProjects;
    },
  },
  mutations: {
    // setNumberOfProjects(state, numOfProjects) {
    //   state.numberOfProjects = numOfProjects;
    // },
    showProjectButtons(state) {
      state.showProjectsButtons = true;
    },
    hideProjectButtons(state) {
      state.showProjectsButtons = false;
    },
    resetTechnologies(state) {
      state.technologyIds = [];
    },
    setNumberOfProjects(state, numOfProjects) {
      state.numberOfProjects = numOfProjects;
    },
    selectProject(state, project) {
      state.selectedProject = project;
      state.selectedProjectId = project['id'];
      state.technologyIds = ProjectTechnologies[state.selectedProjectId].technologies;
    },
    setProjectsByLanguage(state, projects) {
      state.selectedProjectsByLanguage = projects;
    },
    setProjectLanguage(state, language) {
      state.selectedLanguage = language;
    },
    setSwitchPostion(state, {posNumber, url=null}) {
      state.switchPosition = posNumber;
      state.showProjectsButtons = false;
      switch (state.switchPosition) {
        case 0:
          router.push({path: '/about'});
          break;
        case 1:
          router.push({path: '/experience'});
          break;
        case 2:
          state.showProjectsButtons = true;
          if (url) {
            router.push({path: url});
          } else {
            router.push({path: '/projects/main'});
          }
          break;
        case 3:
          router.push({path: '/blogs'});
          break;
        case 4:
          router.push({path: '/school_projects'});
          break;
        case 5:
          router.push({path: '/credits'});
          break;
        // case 6:
        //   router.push({path: '/ch7'});
        //   break;
        // case 7:
        //   router.push({path: '/credits'});
        //   break;
      }
    },
    setSchoolProjects(state, data) {
      state.schoolProjects = data;
    },
  },
});
// app.config.devtools = true;
app.use(store);
app.use(router);
app.mount('#app');
