<template>
  <div class="flex flex-col h-full text-3xl justify-around">
    <div class="py-6 text-base sm:text-2xl md:text-4xl">
      This is the projects channel.
    </div>
    <div class="py-6 text-base sm:text-2xl md:text-3xl">
      Please select language from the controls section.
    </div>
    <div class="hidden md:block py-6 text-2xl">
      In the upper right screen you will find the stack that has been used in specific projects.
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsMain',
  created: function(event) {
    this.$store.commit('resetTechnologies');
  },
};
</script>
