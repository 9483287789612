<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center relative">
        <div class="hiddem sm:block glitch text-2xl sm:text-5xl pb-4" data-text="School">
          School
        </div>
        <div class="flex flex-col absolute top-0 -right-2 sm:right-0">
          <div class="flex items-center">
            <div :class="{'play-active': animationState=='running', 'play-inactive': animationState=='paused'}"></div>
            <div
              class="text-sm sm:text-base ml-1 sm:ml-2"
              :class="{'opacity-1': animationState=='running', 'opacity-50': animationState=='paused'}"
            >Play</div>
          </div>
          <div class="flex items-center">
            <div :class="{'paused-active': animationState=='paused', 'paused-inactive': animationState=='running'}"></div>
            <div
              class="text-sm sm:text-base ml-1 sm:ml-2"
            :class="{'opacity-1': animationState=='paused', 'opacity-50': animationState=='running'}"
            >Pause</div>
          </div>
        </div>
      </div>
      <div
        class="flex w-full scrollable-tv-text-container"
        @click="toggleRunningAnimation($event)"
      >
        <div class="w-full scrollable-tv-text" :style="`--animation-state: ${animationState}`">
          <ul class="text-center text-2xl">
            <li class="p-2 pb-8" v-for="(item) in schoolProjects['projects']" :key=item.title>
              <div class="text-center text-base sm:text-xl md:text-2xl xl:text-3xl pb-4">
                {{ item.curriculum }} - {{ item.title }}
              </div>
              <div class="text-left text-base md:text-xl xl:text-2xl pb-2">
                {{ item.abstract }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolProjects',
  props: {
    title: String,
  },
  data: function() {
    return {
      animationState: 'running',
    };
  },
  methods: {
    toggleRunningAnimation: function(event) {
      this.runAnimation = !this.runAnimation;
      if (this.animationState == 'running') {
        this.animationState = 'paused';
      } else {
        this.animationState = 'running';
      }
    },
  },
  computed: {
    schoolProjects() {
      return this.$store.getters.schoolProjects;
    },
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 4});
  },
};
</script>
