<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center">
        <div class="glitch text-xl sm:text-5xl pb-4" data-text="Projects">
          Projects
        </div>
      </div>
      <div class="flex w-full scrollable-tv-text-container overflow-auto sm:overflow-none">
        <div class="w-full scrollable-tv-text-none">
          <router-view :key="$route.fullPath"></router-view>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Projects',
  props: {
    title: String,
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 2});
  },
};
</script>
