<template>
  <div class="w-full flex flex-col h-full justify-between">
    <div class="w-full flex flex-wrap justify-around">
      <div
        class="skill md:max-w-48 xl:max-w-96"
        :style="
          `--color-1: ${skillColor1};
          --color-2: ${skillColor2};
          --color-3: ${skillColor3};
          --color-4: ${skillColor4};
          --color-5: ${skillColor5}`
          "
        >
        <img :src="setImgSrc" alt="ruby.svg">
      </div>
    </div>
  </div>
</template>

<script>

import {Images} from '../constants.js';
export default {
  name: 'StackItem',
  data: function() {
    return {
      skillColor1: '#FFFFFF',
      skillColor2: '#FFFFFF',
      skillColor3: '#FFFFFF',
      skillColor4: '#FFFFFF',
      skillColor5: '#FFFFFF',
    };
  },
  props: {
    technologyID: String,
  },
  computed: {
    testTechnologies() {
      return this.$store.getters.technologies;
    },
    setImgSrc() {
      return Images[this.technologyID];
    },
  },
  methods: {
    setItemColors(id) {
      switch (id) {
        case '1': // ruby
        case '2': // ror
          this.skillColor1 = '#730d17';
          this.skillColor2 = '#8a0f1c';
          this.skillColor3 = '#9b111e';
          this.skillColor4 = '#a11220';
          this.skillColor5 = '#b81425';
          break;
        case '3': // postgresql
          this.skillColor1 = '#2e4e6b';
          this.skillColor2 = '#365a7d';
          this.skillColor3 = '#3d668d';
          this.skillColor4 = '#4574a1';
          this.skillColor5 = '#4d81b3';
          break;
        case '4': // tailwindcss
          this.skillColor1 = '#54b0b8';
          this.skillColor2 = '#4babb4';
          this.skillColor3 = '#54b0b8';
          this.skillColor4 = '#5db3bb';
          this.skillColor5 = '#6fbcc3';
          break;
        case '5': // heroku
          this.skillColor1 = '#514e7e';
          this.skillColor2 = '#5b578e';
          this.skillColor3 = '#635f9c';
          this.skillColor4 = '#65619e';
          this.skillColor5 = '#7471a8';
          break;
        case '6': // angular
          this.skillColor1 = '#9f332d';
          this.skillColor2 = '#b33932';
          this.skillColor3 = '#c73e38';
          this.skillColor4 = '#cd524c';
          this.skillColor5 = '#d26660';
          break;
        case '7': // nodejs
          this.skillColor1 = '#7bae37';
          this.skillColor2 = '#88c23d';
          this.skillColor3 = '#8fc548';
          this.skillColor4 = '#94c851';
          this.skillColor5 = '#a0ce64';
          break;
        case '8': // ionic
          this.skillColor1 = '#2d66eb';
          this.skillColor2 = '#4477ee';
          this.skillColor3 = '#5584ef';
          this.skillColor4 = '#5c88f0';
          this.skillColor5 = '#7399f2';
          break;
        case '9': // vue
          this.skillColor1 = '#4d996e';
          this.skillColor2 = '#55aa7a';
          this.skillColor3 = '#61b083';
          this.skillColor4 = '#66b287';
          this.skillColor5 = '#77bb95';
          break;
        case '10': // cpp
          this.skillColor1 = '#193b67';
          this.skillColor2 = '#1e467b';
          this.skillColor3 = '#235492';
          this.skillColor4 = '#285ea4';
          this.skillColor5 = '#2d69b9';
          break;
        case '11': // bash
          this.skillColor1 = '#b3b3b3';
          this.skillColor2 = '#bfbfbf';
          this.skillColor3 = '#cccccc';
          this.skillColor4 = '#d9d9d9';
          this.skillColor5 = '#e6e6e6';
          break;
        case '12': // freeRtos
          this.skillColor1 = '#b1f7a1';
          this.skillColor2 = '#9df589';
          this.skillColor3 = '#8df476';
          this.skillColor4 = '#89f471';
          this.skillColor5 = '#76f25a';
          break;
        case '13': // win32
          this.skillColor1 = '#e9bb2f';
          this.skillColor2 = '#ecc246';
          this.skillColor3 = '#ecc54e';
          this.skillColor4 = '#eeca5d';
          this.skillColor5 = '#f0d175';
          break;
        case '14': // linux
          this.skillColor1 = '#ebb914';
          this.skillColor2 = '#edc02c';
          this.skillColor3 = '#efc844';
          this.skillColor4 = '#f1ce5b';
          this.skillColor5 = '#f3d572';
          break;
        case '15': // stripe
          this.skillColor1 = '#4952d0';
          this.skillColor2 = '#5d65d5';
          this.skillColor3 = '#666ed7';
          this.skillColor4 = '#7178da';
          this.skillColor5 = '#858be0';
          break;
        case '16': // sendgrid
          this.skillColor1 = '#2f9ad0';
          this.skillColor2 = '#44a4d5';
          this.skillColor3 = '#4da9d7';
          this.skillColor4 = '#59aed9';
          this.skillColor5 = '#6eb8de';
          break;
        case '17': // cloudinary
          this.skillColor1 = '#2e3b9e';
          this.skillColor2 = '#3442b2';
          this.skillColor3 = '#3645b8';
          this.skillColor4 = '#394ac6';
          this.skillColor5 = '#4d5ccb';
          break;
        case '18': // ngrx
          this.skillColor1 = '#872ca0';
          this.skillColor2 = '#9831b4';
          this.skillColor3 = '#a636c5';
          this.skillColor4 = '#a937c8';
          this.skillColor5 = '#b14bce';
          break;
        case '19': // bootstrap
          this.skillColor1 = '#392b54';
          this.skillColor2 = '#443465';
          this.skillColor3 = '#4f3c74';
          this.skillColor4 = '#5b4587';
          this.skillColor5 = '#674e97';
          break;
        case '20': // primeng
          this.skillColor1 = '#a72530';
          this.skillColor2 = '#bc2936';
          this.skillColor3 = '#c52b37';
          this.skillColor4 = '#d12e3b';
          this.skillColor5 = '#d6434f';
          break;
        case '21': // lua
          this.skillColor1 = '#1a1aff';
          this.skillColor2 = '#3333ff';
          this.skillColor3 = '#4d4dff';
          this.skillColor4 = '#6666ff';
          this.skillColor5 = '#8080ff';
          break;
        case '22': // uboot
          this.skillColor1 = '#e9b363';
          this.skillColor2 = '#ecbe79';
          this.skillColor3 = '#efc78d';
          this.skillColor4 = '#f2d4a6';
          this.skillColor5 = '#f5debc';
          break;
        case '23': // angularMaterial
          this.skillColor1 = '#cb731a';
          this.skillColor2 = '#e2801d';
          this.skillColor3 = '#e58d33';
          this.skillColor4 = '#e8994a';
          this.skillColor5 = '#eaa661';
          break;
        case '24': // graphql
          this.skillColor1 = '#bd0f86';
          this.skillColor2 = '#d41197';
          this.skillColor3 = '#db129b';
          this.skillColor4 = '#ec13a7';
          this.skillColor5 = '#ee2bb0';
          break;
        case '25': // aws-s3
        case '26': // aws-lambda
          this.skillColor1 = '#be520e';
          this.skillColor2 = '#d55c10';
          this.skillColor3 = '#e76312';
          this.skillColor4 = '#ed6612';
          this.skillColor5 = '#ef752a';
          break;
      }
    },
  },
  created: function(event) {
    this.setItemColors(this.technologyID);
  },
};
</script>
