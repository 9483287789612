<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center relative">
        <div class="glitch text-2xl sm:text-5xl pb-4" data-text="About Me">
          About Me
        </div>
        <div class="flex flex-col absolute top-0 -right-2 sm:right-0">
          <div class="flex items-center">
            <div :class="{'play-active': animationState=='running', 'play-inactive': animationState=='paused'}"></div>
            <div
              class="text-sm sm:text-base ml-1 sm:ml-2"
              :class="{'opacity-1': animationState=='running', 'opacity-50': animationState=='paused'}"
            >Play</div>
          </div>
          <div class="flex items-center">
            <div :class="{'paused-active': animationState=='paused', 'paused-inactive': animationState=='running'}"></div>
            <div
              class="text-sm sm:text-base ml-1 sm:ml-2"
            :class="{'opacity-1': animationState=='paused', 'opacity-50': animationState=='running'}"
            >Pause</div>
          </div>
        </div>
      </div>
      <div
        class="flex w-full scrollable-tv-text-container"
        @click="toggleRunningAnimation($event)"
      >
        <div class="w-full scrollable-tv-text" :style="`--animation-state: ${animationState}`">
          <div class="text-base sm:text-xl">
            Over 8 years of experience in software development, web development,
            desktop application development, graphical libraries development, embedded
            firmware, designing and developing low level graphical software engines and
            applications, software QA, review, test and verification.
          </div>
          <br>
          <div class="text-base sm:text-3xl xl:text-4xl underline pb-4">EDUCATION</div>
          <div class="text-base sm:text-xl">
            <div>
              <div>
                School of Electrical Engineering, University of Belgrade
                M.Sc. In Computer Science (2012 - 2013)
              </div>
              <div>
                Computer Engineering and Information Technology Department,
                School of Electrical Engineering, University of Belgrade
              </div>
              <div>
                <div>GPA 9.33</div>
                <div>Thesis: Custom package integration in Buildroot generated Linux distribution</div>
              </div>
              <div class="mt-4">
                School of Electrical Engineering, University of Belgrade
                B.Sc. in Computer Science (2007 - 2012)
              </div>
              <div>
                Computer Engineering and Information Technology Department,
                School of Electrical Engineering, University of Belgrade</div>
              <div>
                <div>GPA 8.18</div>
                <div>Thesis: Internet of Things: Sensinode sensors - applications development</div>
              </div>
            </div>
          </div>
          <br>
          <div class="text-base sm:text-3xl xl:text-4xl underline pb-4">
            PROFESSIONAL INTEREST
          </div>
          <div class="text-base sm:text-xl">
            Have interests in Web/Full-stack development, HMI, graphical software
            engines, compilers, concurrent programming, agile programming, algorithms,
            micro controllers, parallel computing, embedded software, performance of
            computer systems.
          </div>
          <br>
          <div class="text-base sm:text-3xl xl:text-4xl underline pb-4">
            PERSONAL CHARACTERISTIC AND INTERESTS
          </div>
          <div class="text-base sm:text-xl">
            Hard working, creative, team player, persistent, versatile, energetic, easy to work with, quick learner.
            Played guitar in youth.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutMe',
  props: {
    title: String,
  },
  data: function() {
    return {
      animationState: 'running',
    };
  },
  methods: {
    toggleRunningAnimation: function(event) {
      this.runAnimation = !this.runAnimation;
      if (this.animationState == 'running') {
        this.animationState = 'paused';
      } else {
        this.animationState = 'running';
      }
    },
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 0});
  },
};
</script>
