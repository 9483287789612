<template>
    <div class="w-full flex flex-wrap">
      <div class="w-1/4 p-1" v-for="item in numberOfProjects" :key=item>
        <input class="channel-button w-full" type="button" :value="item" v-on:click="selectProject(item)" :class="isPressed(item)">
      </div>
    </div>
</template>

<script>

export default {
  name: 'ProjectsButtons',
  components: {

  },
  computed: {
    numberOfProjects() {
      return this.$store.getters.numOfProject;
    },
    projects() {
      return this.$store.getters.projectsLanguage;
    },
    projectID() {
      return this.$store.getters.currentProjectID;
    },
  },
  data: function() {
    return {
      channelPressed: 0,
      resetSelectedButton: true,
    };
  },
  props: {
    msg: String,
    currentLanguage: String,
  },
  watch: {
    currentLanguage: function(newVal, oldVal) {
      this.resetSelectedButton = true;
      this.channelPressed = 0;
    },
  },
  methods: {
    isPressed: function(item) {
      return (this.channelPressed == item) && !this.resetSelectedButton ? 'channel-button-selected' : '';
    },
    selectProject: function(event) {
      this.resetSelectedButton = false;
      this.channelPressed = event;
      const pId = event - 1;
      const currentLang = this.$store.getters.currentProjectLanguage;
      this.$store.commit('selectProject', this.projects[pId.toString()]);
      this.$router.push({name: 'singleProject', params: {lang: currentLang, id: this.projectID.toString()}});
    },
    getProjectButtonIDFromProjectID(projectID) {
      this.$store.getters.projectsLanguage.forEach( (project, index) => {
        if (project.id === projectID.toString()) {
          this.resetSelectedButton = false;
          this.channelPressed = index + 1;
          const pId = index;
          this.$store.commit('selectProject', this.projects[pId.toString()]);
        }
      });
    },
  },
  created: function(event) {
    // This is needed when refreshing or copy-pasting the route with single project
    this.getProjectButtonIDFromProjectID(this.$store.getters.currentProjectID);
  },
};
</script>
