<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center">
        <div class="glitch text-2xl sm:text-5xl pb-0 sm:pb-4" data-text="Blogs">
          Blogs
        </div>
      </div>
      <div class="flex w-full scrollable-tv-text-container">
        <div class="scrollable-tv-text-none w-full">
          <div class="flex-col w-full">
            <div class="flex-col w-full text-left">
              <div class="text-base sm:text-3xl py-4 text-center">
                <a
                  href="https://medium.com"
                  target="_blank"
                >Medium</a>
              </div>
              <div class="text-base sm:text-xl flex w-full">
                <div class="mr-1">
                  🔗
                </div>
                <div>
                  <a
                    href="https://medium.com/@moneywithin/tailwindcss-angular8-a13da07b4dae"
                    target="_blank"
                  >TailwindCSS & Angular8</a>
                </div>
              </div>
              <div class="text-base sm:text-xl flex w-full">
                <div class="mr-1">
                  🔗
                </div>
                <div>
                  <a
                    href="https://medium.com/@moneywithin/react-express-api-on-heroku-ad0b73fad3ca"
                    target="_blank"
                  >React + Express API on Heroku</a>
                </div>
              </div>
              <div class="text-base sm:text-3xl py-4 text-center">
                <a
                  href="https://dev.to/"
                  target="_blank"
                >Dev.to</a>
              </div>
              <div class="text-base sm:text-xl flex w-full">
                <div class="mr-1">
                  🔗
                </div>
                <div>
                  <a
                    href="https://dev.to/laptoptheone/tailwindcss-angular8-134b"
                    target="_blank"
                  >TailwindCSS & Angular8</a>
                </div>
              </div>
              <div class="text-base sm:text-xl flex w-full">
                <div class="mr-1">
                  🔗
                </div>
                <div>
                  <a
                    href="https://dev.to/laptoptheone/angular-material-datepicker-icon-tailwindcss-11p1"
                    target="_blank"
                  >Angular Material Datepicker Icon + TailwindCSS</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Blogs',
  props: {
    title: String,
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 3});
  },
};
</script>
