export const TechnologiesEnum = {
  'ruby': '1',
  'ror': '2',
  'postgresql': '3',
  'tailwindcss': '4',
  'heroku': '5',
  'angular': '6',
  'nodejs': '7',
  'ionic': '8',
  'vue': '9',
  'cpp': '10',
  'bash': '11',
  'freeRtos': '12',
  'win32': '13',
  'linux': '14',
  'stripe': '15',
  'sendgrid': '16',
  'cloudinary': '17',
  'ngrx': '18',
  'bootstrap': '19',
  'primeng': '20',
  'lua': '21',
  'uboot': '22',
  'angularMaterial': '23',
  'graphql': '24',
  'aws-s3': '25',
  'aws-lambda': '26',
};

export const ProjectsEnum = {
  'WI5': '18',
  'KB': '17',
  'EPI': '16',
  'WPRUBY': '15',
  'WPPORT': '14',
  'WPBNA': '13',
  'BP': '12',
  'SS': '11',
  'RSPB': '10',
  'HOMES': '9',
  'FINGO': '8',
  'JBB': '7',
  'GWG': '6',
  'GWR': '5',
  'GUISTM': '4',
  'GUIREN': '3',
  'UGUI': '2',
  'MASTER': '1',
};

export const ProjectTechnologies = {
  [ProjectsEnum.WI5]: {
    technologies: [
      TechnologiesEnum.ruby, TechnologiesEnum.ror, TechnologiesEnum.postgresql, TechnologiesEnum.vue,
      TechnologiesEnum['aws-s3'], TechnologiesEnum['aws-lambda']],
  },
  [ProjectsEnum.KB]: {
    technologies: [TechnologiesEnum.ruby, TechnologiesEnum.ror, TechnologiesEnum.postgresql, TechnologiesEnum.vue, TechnologiesEnum.graphql],
  },
  [ProjectsEnum.EPI]: {
    technologies: [TechnologiesEnum.ruby, TechnologiesEnum.ror, TechnologiesEnum.postgresql, TechnologiesEnum.bootstrap],
  },
  [ProjectsEnum.WPRUBY]: {
    technologies: [TechnologiesEnum.ruby, TechnologiesEnum.ror, TechnologiesEnum.postgresql, TechnologiesEnum.bootstrap],
  },
  [ProjectsEnum.WPPORT]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.ngrx, TechnologiesEnum.tailwindcss, TechnologiesEnum.primeng],
  },
  [ProjectsEnum.WPBNA]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.angularMaterial],
  },
  [ProjectsEnum.BP]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.nodejs, TechnologiesEnum.postgresql,
      TechnologiesEnum.tailwindcss, TechnologiesEnum.heroku, TechnologiesEnum.sendgrid, TechnologiesEnum.cloudinary],
  },
  [ProjectsEnum.SS]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.nodejs, TechnologiesEnum.postgresql,
      TechnologiesEnum.bootstrap, TechnologiesEnum.heroku, TechnologiesEnum.sendgrid, TechnologiesEnum.cloudinary, TechnologiesEnum.stripe],
  },
  [ProjectsEnum.RSPB]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.bootstrap, TechnologiesEnum.primeng],
  },
  [ProjectsEnum.HOMES]: {
    technologies: [TechnologiesEnum.angular, TechnologiesEnum.ionic, TechnologiesEnum.bootstrap],
  },
  [ProjectsEnum.FINGO]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.ruby, TechnologiesEnum.ror, TechnologiesEnum.postgresql, TechnologiesEnum.win32],
  },
  [ProjectsEnum.JBB]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.win32],
  },
  [ProjectsEnum.GWG]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.lua, TechnologiesEnum.linux, TechnologiesEnum.uboot, TechnologiesEnum.bash],
  },
  [ProjectsEnum.GWR]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.lua, TechnologiesEnum.linux, TechnologiesEnum.uboot, TechnologiesEnum.bash],
  },
  [ProjectsEnum.GUISTM]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.linux, TechnologiesEnum.uboot, TechnologiesEnum.bash],
  },
  [ProjectsEnum.GUIREN]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.linux, TechnologiesEnum.uboot, TechnologiesEnum.bash],
  },
  [ProjectsEnum.UGUI]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.freeRtos],
  },
  [ProjectsEnum.MASTER]: {
    technologies: [TechnologiesEnum.cpp, TechnologiesEnum.linux, TechnologiesEnum.uboot, TechnologiesEnum.bash],
  },
};

export const Images = {
  [TechnologiesEnum.ruby]: require('./assets/svg/ruby-stencil.svg'),
  [TechnologiesEnum.ror]: require('./assets/svg/ror-stencil.svg'),
  [TechnologiesEnum.postgresql]: require('./assets/svg/postgresql-stencil.svg'),
  [TechnologiesEnum.tailwindcss]: require('./assets/svg/tailwindcss-stencil.svg'),
  [TechnologiesEnum.heroku]: require('./assets/svg/heroku-stencil.svg'),
  [TechnologiesEnum.angular]: require('./assets/svg/angular-stencil.svg'),
  [TechnologiesEnum.nodejs]: require('./assets/svg/nodejs-stencil.svg'),
  [TechnologiesEnum.ionic]: require('./assets/svg/ionic-stencil.svg'),
  [TechnologiesEnum.vue]: require('./assets/svg/vuejs-stencil.svg'),
  [TechnologiesEnum.cpp]: require('./assets/svg/c++-stencil.svg'),
  [TechnologiesEnum.bash]: require('./assets/svg/bash-stencil.svg'),
  [TechnologiesEnum.freeRtos]: require('./assets/svg/freertos-stencil.svg'),
  [TechnologiesEnum.win32]: require('./assets/svg/win-stencil.svg'),
  [TechnologiesEnum.linux]: require('./assets/svg/linux-stencil.svg'),
  [TechnologiesEnum.stripe]: require('./assets/svg/stripe-stencil.svg'),
  [TechnologiesEnum.sendgrid]: require('./assets/svg/sendgrid-stencil.svg'),
  [TechnologiesEnum.cloudinary]: require('./assets/svg/cloudinary-stencil.svg'),
  [TechnologiesEnum.ngrx]: require('./assets/svg/ngrx-stencil.svg'),
  [TechnologiesEnum.bootstrap]: require('./assets/svg/bootstrap-stencil.svg'),
  [TechnologiesEnum.primeng]: require('./assets/svg/primeng-stencil.svg'),
  [TechnologiesEnum.lua]: require('./assets/svg/lua-stencil.svg'),
  [TechnologiesEnum.uboot]: require('./assets/svg/uboot-stencil.svg'),
  [TechnologiesEnum.angularMaterial]: require('./assets/svg/angular-material-stencil.svg'),
  [TechnologiesEnum.graphql]: require('./assets/svg/graphql-stencil.svg'),
  [TechnologiesEnum['aws-lambda']]: require('./assets/svg/aws-lambda.svg'),
  [TechnologiesEnum['aws-s3']]: require('./assets/svg/aws-s3.svg'),
};
