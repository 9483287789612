<template>
  <div class="flex flex-col h-full">
    <div class="flex flex-col w-full h-full">
      <div class="flex w-full justify-center">
        <div class="glitch text-2xl sm:text-5xl pb-4" data-text="Credits">
          Credits
        </div>
      </div>
      <div class="flex w-full scrollable-tv-text-container">
        <div class="w-full scrollable-tv-text-none">
          <div class="text-base sm:text-xl text-center">
           <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://v3.vuejs.org/"
                  target="_blank"
                >Vue 3</a>
              </div>
            </div>
            <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://tailwindcss.com/"
                  target="_blank"
                >Tailwindcss</a>
              </div>
            </div>
            <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://postcss.org/"
                  target="_blank"
                >Postcss</a>
              </div>
            </div>
            <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://fonts.google.com/specimen/Roboto+Mono"
                  target="_blank"
                >Roboto Mono</a>
              </div>
            </div>
            <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://css-tricks.com/glitch-effect-text-images-svg/"
                  target="_blank"
                >Glitch effect</a>
              </div>
            </div>
           <div class="text-base sm:text-xl flex w-full">
              <div class="mr-1">
                🔗
              </div>
              <div>
                <a
                  href="https://www.npmjs.com/package/compass-mixins"
                  target="_blank"
                >Compass-mixins</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Credits',
  props: {
    title: String,
  },
  created: function(event) {
    this.$store.commit('setSwitchPostion', {posNumber: 5});
  },
};
</script>
