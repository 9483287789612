<template>
  <div class="switch-control w-full flex break-words py-1 xl:py-4 px-1 xl:p-2 text-black
    text-center michroma-regular-font text-xs">
    <div class="hidden xl:flex flex-col w-1/3 justify-around text-left">
      <div> {{ position_5_label }} </div>
      <div> {{ position_4_label }} </div>
    </div>
    <div class="hidden xl:flex flex-col w-1/3 text-center">
      <div> {{ position_0_label }} </div>
      <div>
        <img class="w-20 p-1 m-auto rotate-switch" src='../assets/svg/switch.svg' alt="switch.svg"
          v-on:click="rotate" :class="getRotateButtonClass"
          :style="`--angle: ${angle}deg`"
          @contextmenu.prevent="reverseRotate">
      </div>
      <div> {{ position_3_label }} </div>
    </div>
    <div class="hidden xl:flex flex-col w-1/3 justify-around text-right">
      <div> {{ position_1_label }} </div>
      <div> {{ position_2_label }} </div>
    </div>

    <div class="hidden md:flex md:flex-col w-full xl:hidden">
      <img class="w-12 p-1 m-auto block md:hidden rotate-switch" src='../assets/svg/switch.svg' alt="switch.svg"
        v-on:click="rotate" :class="getRotateButtonClass"
        :style="`--angle: ${angle}deg`"
        @contextmenu.prevent="reverseRotate">
      <div class="flex flex-col w-full switch-scroll-screen">
        <div class="flex w-full h-full justify-between items-center">
          <div></div>
          <div class="flex flex-col text-xs md:text-tiny lg:text-xs">
            <div>
              <div class="text-gray-400">
                {{ getPreviousLabel }}
              </div>
              <div class="text-white">
                {{ getCurrentLabel }}
              </div>
              <div class="text-gray-400">
                {{ getNextLabel }}
              </div>
            </div>
          </div>
          <div class="text-red-500">&#9668;</div>
        </div>
      </div>
      <img class="w-20 p-1 m-auto hidden md:block rotate-switch" src='../assets/svg/switch.svg' alt="switch.svg"
        v-on:click="rotate" :class="getRotateButtonClass"
        :style="`--angle: ${angle}deg`"
        @contextmenu.prevent="reverseRotate">
    </div>
    <div class="flex flex-col w-full md:hidden">
      <div class="flex flex-col w-full switch-scroll-screen" v-on:click="rotate" @contextmenu.prevent="reverseRotate">
        <div class="flex w-full h-full justify-between items-center">
          <div></div>
          <div class="flex flex-col text-xs md:text-tiny lg:text-xs">
            <div>
              <div class="text-gray-400">
                {{ getPreviousLabel }}
              </div>
              <div class="text-white">
                {{ getCurrentLabel }}
              </div>
              <div class="text-gray-400">
                {{ getNextLabel }}
              </div>
            </div>
          </div>
          <div class="text-red-500">&#9668;</div>
        </div>
      </div>
      <img class="w-20 p-1 m-auto hidden md:block" src='../assets/svg/switch.svg' alt="switch.svg"
        v-on:click="rotate" :class="getRotateButtonClass"
        @contextmenu.prevent="reverseRotate">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchControl',
  components: {

  },
  props: {
    msg: String,
    position_0_label: String,
    position_1_label: String,
    position_2_label: String,
    position_3_label: String,
    position_4_label: String,
    position_5_label: String,
  },
  data: function() {
    return {
      rotateButtonClass: 'rotate-switch-pos-0',
      angle: 0,
      startAngle: 0,
    };
  },
  created: function(event) {
  },
  computed: {
    getSwitchPosition() {
      return this.$store.getters.switchPosition;
    },
    getRotateButtonClass() {
      // This computed prop is for the initial correct setup of the switch
      this.setStartAngle(this.getSwitchPosition * 60);
      if (this.angle == 0) {
        this.setAngle(this.startAngle);
      }
      return '';
    },
    getNextLabel() {
      let label = '';
      switch (this.getSwitchPosition) {
        case 0:
          label = this.position_1_label;
          break;
        case 1:
          label = this.position_2_label;
          break;
        case 2:
          label = this.position_3_label;
          break;
        case 3:
          label = this.position_4_label;
          break;
        case 4:
          label = this.position_5_label;
          break;
        case 5:
          label = this.position_0_label;
          break;
      }
      return label;
    },
    getCurrentLabel() {
      let label = '';
      switch (this.getSwitchPosition) {
        case 0:
          label = this.position_0_label;
          break;
        case 1:
          label = this.position_1_label;
          break;
        case 2:
          label = this.position_2_label;
          break;
        case 3:
          label = this.position_3_label;
          break;
        case 4:
          label = this.position_4_label;
          break;
        case 5:
          label = this.position_5_label;
          break;
      }
      return label;
    },
    getPreviousLabel() {
      let label = '';
      switch (this.getSwitchPosition) {
        case 0:
          label = this.position_5_label;
          break;
        case 1:
          label = this.position_0_label;
          break;
        case 2:
          label = this.position_1_label;
          break;
        case 3:
          label = this.position_2_label;
          break;
        case 4:
          label = this.position_3_label;
          break;
        case 5:
          label = this.position_4_label;
          break;
      }
      return label;
    },
  },
  methods: {
    setStartAngle(angle) {
      this.startAngle = angle;
    },
    setAngle(angle) {
      this.angle = angle;
    },
    rotate: function(event) {
      const step = (this.getSwitchPosition + 1)%6;
      this.angle += 60;
      this.$store.commit('setSwitchPostion', {posNumber: step});
    },
    reverseRotate: function(event) {
      this.angle -= 60;
      if (this.getSwitchPosition == 0) {
        const step = 5;
        this.$store.commit('setSwitchPostion', {posNumber: step});
      } else {
        const step = (this.getSwitchPosition - 1)%6;
        this.$store.commit('setSwitchPostion', {posNumber: step});
      }
    },
  },
};
</script>
