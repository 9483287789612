<template>
    <TvBoard msg="Milos's website..."/>
</template>

<script>
import schoolProjectData from './assets/text/school_project_data.json';
import TvBoard from './components/TvBoard.vue';

export default {
  name: 'App',
  components: {
    TvBoard,
  },
  created: function(event) {
    this.$store.commit('setSchoolProjects', schoolProjectData);
  },
};
</script>
