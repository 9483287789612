<template>
  <div class="flex flex-col h-full text-xl lg:text-3xl text-left">
    <div class="flex w-full py-2">
      <div class="hidden md:flex md:flex-col w-1/4 xl:w-1/6">
        <div>Title:</div>
      </div>
      <div class="flex-col w-full md:w-3/4 xl:w-5/6 text-sm md:text-xl lg:text-3xl">
        <div> {{ selectedProject.title }} </div>
      </div>
    </div>
    <div class="flex w-full py-2">
      <div class="hidden md:flex md:flex-col w-1/4 xl:w-1/6">
        <div>Abstract:</div>
      </div>
      <div class="flex-col w-full md:w-3/4 xl:w-5/6 text-sm md:text-xl lg:text-3xl">
        <div> {{ selectedProject.abstract }}</div>
      </div>
    </div>
    <div class="flex w-full py-2">
      <div class="hidden md:flex md::flex-col w-1/4 xl:w-1/6">
        <div>Stack:</div>
      </div>
      <div class="flex lg:flex-col w-full md:w-3/4 xl:w-5/6 text-sm md:text-xl lg:text-3xl flex-wrap lg:flex-nowrap">
        <div class="mr-4 lg:mr-0" v-for="item in selectedProject.technologies" :key=item>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import projectData from '../assets/text/project_data.json';
import projectData from '../assets/text/project_data.json';
export default {
  name: 'SingleProject',
  computed: {
    selectedProject() {
      return this.$store.getters.currentProject;
    },
  },
  created: function(event) {
    const currentLanguage = this.$router.currentRoute._value.params.lang;
    const currentProjectID = this.$router.currentRoute._value.params.id;
    const languageData = projectData[currentLanguage];
    const listOfProjects = languageData['projects'];

    this.$store.commit('setProjectLanguage', currentLanguage);
    this.$store.commit('setNumberOfProjects', listOfProjects.length);
    this.$store.commit('setProjectsByLanguage', listOfProjects);
    listOfProjects.forEach((element) => {
      if (element.id == currentProjectID) {
        this.$store.commit('selectProject', element);
        this.$store.commit('setSwitchPostion', {posNumber: 2, url: this.$router.currentRoute._value.fullPath});
      }
    });
  },
};
</script>
