<template>
  <div class="w-full flex md:flex-col h-full">
    <ButtonsGroup v-if="showButtonsGroup"/>
    <div v-if="!showButtonsGroup" class="w-1/2 md:w-full flex flex-col h-full justify-between"></div>
    <div class="w-1/2 sm:w-1/4 md:w-full">
    <SwitchControl
        position_0_label="About"
        position_1_label="Experience"
        position_2_label="Projects"
        position_3_label="Blogs"
        position_4_label="School Projects"
        position_5_label="Credits"
      />
    </div>
  </div>
</template>

<script>
import SwitchControl from './SwitchControl.vue';
import ButtonsGroup from './ButtonsGroup.vue';

export default {
  name: 'SwitchControlPanel',
  components: {
    SwitchControl,
    ButtonsGroup,
  },
  computed: {
    showButtonsGroup() {
      return this.$store.getters.showProjectButtons;
    },
  },
  props: {
    msg: String,
  },
};
</script>
