<template>
  <div class="w-1/2 md:w-full flex flex-col h-full justify-between">
    <StacksScreen/>
    <div class="w-full sm:w-1/2 md:w-full">
      <div class="py-1 xl:py-8">
        <ProjectsButtons :currentLanguage="channelPressed" v-if="showProjectsButtons"/>
      </div>
      <div class="w-full flex md:flex-col xl:flex-row justify-between">
        <div class="w-1/3 md:w-full xl:w-1/3 p-1">
          <input class="channel-button w-full" type="button" value="RUBY" v-on:click="selectLanguage('ruby')" :class="isPressed('ruby')">
        </div>
        <div class="w-1/3 md:w-full xl:w-1/3 p-1">
          <input class="channel-button w-full" type="button" value="JS/TS" v-on:click="selectLanguage('js')" :class="isPressed('js')">
        </div>
        <div class="w-1/3 md:w-full xl:w-1/3 p-1">
          <input class="channel-button w-full" type="button" value="C/C++" v-on:click="selectLanguage('c++')" :class="isPressed('c++')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StacksScreen from './StacksScreen.vue';
import ProjectsButtons from './ProjectsButtons.vue';
export default {
  name: 'ButtonsGroup',
  components: {
    ProjectsButtons,
    StacksScreen,
  },
  data: function() {
    return {
      showProjectsButtons: false,
      channelPressed: '',
    };
  },
  methods: {
    isPressed: function(item) {
      return this.channelPressed == item ? 'channel-button-selected' : '';
    },
    selectLanguage: function(languageType) {
      this.channelPressed = languageType;
      this.showProjectsButtons = true;
      this.$store.commit('setProjectLanguage', languageType);
      switch (languageType) {
        case 'ruby':
          this.$router.push({name: 'language', params: {lang: 'ruby'}});
          break;
        case 'js':
          this.$router.push({name: 'language', params: {lang: 'js'}});
          break;
        case 'c++':
          this.$router.push({name: 'language', params: {lang: 'c++'}});
          break;
      }
    },
  },
  created: function(event) {
    // This is needed when refreshing or copy-pasting the route with single project
    const currentLanguage = this.$router.currentRoute._value.params.lang;
    if (currentLanguage) {
      this.channelPressed = currentLanguage;
      this.showProjectsButtons = true;
      this.$store.commit('setProjectLanguage', currentLanguage);
    }
  },
};
</script>
